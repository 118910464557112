.live { 
    overflow: hidden; 
}
  
  .live > li { 
    list-style: none; 
    position: relative; 
    padding: 0 0 0 2em;
    margin: 0 0 .35em 8px;
    transition: .12s;
  }
  
  .live > li::before {
    position: absolute;
    content: '\2022'; 
    color: #444141;
    top: 0;
    left: 0;
    text-align: center; 
    font-size: 2em; 
    opacity: .5;
    line-height: .75; 
    transition: .5s;
  }
  
  .live > li:hover {
    color: #000;
    font-size: 17px;
  }
  
  .live > li:hover::before {
    transform: scale(2); 
    opacity: 1; 
    text-shadow: 0 0 4px;
    transition: .1s;
  }
  
