@tailwind base;
@tailwind components;
@tailwind utilities;

.App {
  text-align: center;
}

html {
  scroll-behavior: smooth;
}

/* Custom scrollbar styling */
::-webkit-scrollbar {
  width: 0.5rem; /* w-10 in Tailwind */
}

::-webkit-scrollbar-thumb {
  background-color: #3b82f6; /* Blue color (Tailwind's 'blue-500') */
  border-radius: 0.75rem; /*rounded-xl in Tailwind*/
}

::-webkit-scrollbar-thumb:hover {
  background-color: #2563eb; /* Darker blue on hover (Tailwind's 'blue-600') */
}

::-webkit-scrollbar-track {
  background-color: #e5e7eb; /* Optional scrollbar track color (Tailwind's 'gray-200') */
  /* border-radius: 0.75rem; rounded-xl */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-float infinite 3s ease-in-out;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: rgb(112, 76, 182);
}

@keyframes App-logo-float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0px);
  }
}
