@tailwind base;
@tailwind components;
@tailwind utilities;

.crop-image {
  position: relative;
  overflow: hidden;
}

.crop-image:after {
  content: "";
  position: absolute;
  top: -90%;
  right: 80%;

  height: 120rem;
  width: 150rem;
  background: white;
  -webkit-transform: rotate(-30deg);
  -moz-transform: rotate(-50deg);
  transform: rotate(20deg);
}

/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
